import { Box, Button, Flex, Heading, Spacer, Text } from '@chakra-ui/react';
import styled from 'styled-components';
import { Project, WorkerItem } from '../../models/Project';
import { AddWorkerForm } from '../../pages/ProjectDetails/AddWorkerForm';
import { useRemoveWorker } from '../../queries/useRemoveWorker';
import { InviteUser } from '../InviteUser/InviteUser';
import { LoadingSpinner } from '../LoadingSpinner';
import { Modal } from '../Modal';
import { TrashIcon } from '../icons/TrashIcon';
// import { validateEmail } from '../../utils/StringUtils';
import { useRemoveUser } from '../../queries/useRemoveUser';

const Divider = styled.div`
	height: ${(props) => props.theme.padding(3)};
`;

const ManageWorkersModalStyled = styled.div`
	h3 {
		display: flex;
		align-items: center;
		margin-bottom: ${(props) => props.theme.padding(2)};
		border-bottom: 1px solid ${(props) => props.theme.colors.border};
		padding-bottom: ${(props) => props.theme.padding(2)};

		svg {
			margin-left: 8px;
		}
	}

	ul {
		list-style-type: none;
		margin: 0;
		padding-left: 0;

		li {
			padding: 12px 0;
			display: flex;
			justify-content: space-between;

			&:not(:last-child) {
				border-bottom: 1px solid ${(props) => props.theme.colors.border};
			}
		}
	}
`;

interface ManageProjectWorkersProps {
	onClose: () => void;
	project: Project;
}

export const ManageProjectWorkers = ({
	onClose,
	project
}: ManageProjectWorkersProps): JSX.Element => {
	const { mutate: removeWorker, isLoading } = useRemoveWorker();
	const { mutate: removeUser, isLoading: isLoadingTwo } = useRemoveUser();

	const remove = async (worker: WorkerItem) => {
		if (worker.type === 1) {
			// Web User
			await removeUser({
				projectId: project.projectId,
				uId: worker.uId
			});
		} else {
			// App user
			await removeWorker({
				projectId: project.projectId,
				uId: worker.uId
			});
		}
	};

	return (
		<Modal title={'Add team members'} open={true} onClose={onClose}>
			<ManageWorkersModalStyled>
				<Text marginBottom={'4'} as={'h4'}>
					To add team members, they must have signed up for GigOver.
				</Text>
				<Flex justifyContent={'stretch'} alignItems={'start'}>
					<Box flexGrow={1}>
						<InviteUser projectId={project.projectId} />
					</Box>
					<Spacer />
					<Box flexGrow={1}>
						<AddWorkerForm projectId={project.projectId} />
					</Box>
				</Flex>
				<Divider />
				<div>
					<Heading as={'h3'} size={'md'}>
						Team members {(isLoading || isLoadingTwo) && <LoadingSpinner />}
					</Heading>
					<ul>
						{project.workers.map((worker, workerIndex) => (
							<li key={workerIndex}>
								{worker.name} {worker.type === 1 ? '(Web user)' : '(App user)'}
								<Button
									size={'sm'}
									variant={'outline'}
									colorScheme={'red'}
									onClick={() => remove(worker)}
								>
									<TrashIcon color={'red'} />
								</Button>
							</li>
						))}
					</ul>
				</div>
			</ManageWorkersModalStyled>
		</Modal>
	);
};
