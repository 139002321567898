import { useMutation, useQueryClient } from 'react-query';
import { ErrorResponse } from '../models/ErrorResponse';
import { ApiService } from '../services/ApiService';
import axios from 'axios';
import { Resource } from '../models/Resource';

interface ModifyResourceResponse {
	errorText: 'OK';
}

export const useModifyResource = () => {
	const queryClient = useQueryClient();

	return useMutation<ModifyResourceResponse, ErrorResponse, Resource>(
		async (resource) =>
			await axios.post(
				resource?.id ? ApiService.editResource : ApiService.addResource,
				resource,
				{ withCredentials: true }
			),
		{
			onSuccess: async () => {
				await queryClient.invalidateQueries(ApiService.resources);
			}
		}
	);
};
