import { Theme } from '../../Theme';
import { IconProps } from '../IconProps';

export const TimeIcon = ({
	size = 24,
	type = 'bold',
	color = Theme.colors.white
}: IconProps): JSX.Element => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			{(() => {
				switch (type) {
					case 'bold':
					case 'medium':
					case 'light':
						return (
							<>
								<path
									d="M6.77137 4.47378C6.48316 4.0038 5.86529 3.85291 5.42336 4.18254C5.03331 4.47346 4.663 4.78994 4.31486 5.1299C3.92041 5.51508 3.97319 6.14891 4.39253 6.50683C4.81187 6.86474 5.43849 6.81021 5.84061 6.43305C6.04293 6.24329 6.25392 6.06297 6.47289 5.89268C6.90809 5.55424 7.05958 4.94377 6.77137 4.47378Z"
									fill={color}
								/>
								<path
									d="M17.2286 4.47378C17.5168 4.0038 18.1347 3.85291 18.5766 4.18254C18.9667 4.47346 19.337 4.78994 19.6851 5.1299C20.0796 5.51508 20.0268 6.14891 19.6075 6.50683C19.1881 6.86474 18.5615 6.81021 18.1594 6.43305C17.9571 6.24329 17.7461 6.06297 17.5271 5.89268C17.0919 5.55424 16.9404 4.94377 17.2286 4.47378Z"
									fill={color}
								/>
								<path
									d="M15.2071 11.2071C15.5976 10.8166 15.5976 10.1834 15.2071 9.79289C14.8166 9.40237 14.1834 9.40237 13.7929 9.79289L12.518 11.0677C12.3528 11.0236 12.1792 11 12 11C10.8954 11 10 11.8954 10 13C10 14.1046 10.8954 15 12 15C13.1046 15 14 14.1046 14 13C14 12.8208 13.9764 12.6472 13.9323 12.482L15.2071 11.2071Z"
									fill={color}
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M10 2C9.44772 2 9 2.44772 9 3C9 3.55228 9.44772 4 10 4H11V5.06189C7.05369 5.55399 4 8.92038 4 13C4 17.4183 7.58172 21 12 21C16.4183 21 20 17.4183 20 13C20 8.92038 16.9463 5.55399 13 5.06189V4H14C14.5523 4 15 3.55228 15 3C15 2.44772 14.5523 2 14 2H10ZM12 19C15.3137 19 18 16.3137 18 13C18 9.68629 15.3137 7 12 7C8.68629 7 6 9.68629 6 13C6 16.3137 8.68629 19 12 19Z"
									fill={color}
								/>
							</>
						);
					case 'solid':
					default:
						return (
							<>
								<path
									d="M6.77131 4.47378C6.4831 4.0038 5.86523 3.85291 5.4233 4.18254C5.03325 4.47346 4.66294 4.78994 4.3148 5.1299C3.92035 5.51508 3.97313 6.14891 4.39247 6.50683C4.81181 6.86474 5.43843 6.81021 5.84055 6.43305C6.04287 6.24329 6.25385 6.06297 6.47283 5.89268C6.90803 5.55424 7.05952 4.94377 6.77131 4.47378Z"
									fill={color}
								/>
								<path
									d="M17.2287 4.47378C17.5169 4.0038 18.1347 3.85291 18.5767 4.18254C18.9667 4.47346 19.337 4.78994 19.6852 5.1299C20.0796 5.51508 20.0268 6.14891 19.6075 6.50683C19.1882 6.86474 18.5615 6.81021 18.1594 6.43305C17.9571 6.24329 17.7461 6.06297 17.5271 5.89268C17.0919 5.55424 16.9404 4.94377 17.2287 4.47378Z"
									fill={color}
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M9 3C9 2.44772 9.44772 2 10 2H14C14.5523 2 15 2.44772 15 3C15 3.55228 14.5523 4 14 4H13V5.06189C16.9463 5.55399 20 8.92038 20 13C20 17.4183 16.4183 21 12 21C7.58172 21 4 17.4183 4 13C4 8.92038 7.05369 5.55399 11 5.06189V4H10C9.44772 4 9 3.55228 9 3ZM15.7071 9.29289C16.0976 9.68342 16.0976 10.3166 15.7071 10.7071L13.9323 12.482C13.9764 12.6472 14 12.8208 14 13C14 14.1046 13.1046 15 12 15C10.8954 15 10 14.1046 10 13C10 11.8954 10.8954 11 12 11C12.1792 11 12.3528 11.0236 12.518 11.0677L14.2929 9.29289C14.6834 8.90237 15.3166 8.90237 15.7071 9.29289Z"
									fill={color}
								/>
							</>
						);
				}
			})()}
		</svg>
	);
};
