import { Theme } from '../../../Theme';
import { IconProps } from '../../IconProps';

export const FilePdfIcon = ({ size = 24, color = Theme.colors.black }: IconProps): JSX.Element => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6 2C4.34315 2 3 3.34315 3 5V19C3 20.6569 4.34315 22 6 22H18C19.6569 22 21 20.6569 21 19V7.74264C21 6.94699 20.6839 6.18393 20.1213 5.62132L17.3787 2.87868C16.8161 2.31607 16.053 2 15.2574 2H6ZM15 4.2071V6C15 7.10457 15.8954 8 17 8H18.7929C19.2383 8 19.4614 7.46143 19.1464 7.14644L15.8536 3.85355C15.5386 3.53857 15 3.76165 15 4.2071ZM7 11C7 10.4477 7.44772 10 8 10H16C16.5523 10 17 10.4477 17 11C17 11.5523 16.5523 12 16 12H8C7.44772 12 7 11.5523 7 11ZM8 14C7.44772 14 7 14.4477 7 15C7 15.5523 7.44772 16 8 16H12C12.5523 16 13 15.5523 13 15C13 14.4477 12.5523 14 12 14H8Z"
			fill={color}
		/>
	</svg>
);
