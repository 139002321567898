import { Theme } from '../../Theme';
import { IconProps } from '../IconProps';

export const TrashIcon = ({ size = 24, color = Theme.colors.black }: IconProps): JSX.Element => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 19 19"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.95835 6.33334C3.52113 6.33334 3.16669 6.68778 3.16669 7.12501V15.0417C3.16669 16.3534 4.23001 17.4167 5.54169 17.4167H13.4584C14.77 17.4167 15.8334 16.3534 15.8334 15.0417V7.12501C15.8334 6.68778 15.4789 6.33334 15.0417 6.33334H3.95835ZM7.12502 8.70834C7.56225 8.70834 7.91669 9.06278 7.91669 9.50001V14.25C7.91669 14.6872 7.56225 15.0417 7.12502 15.0417C6.68779 15.0417 6.33335 14.6872 6.33335 14.25V9.50001C6.33335 9.06278 6.68779 8.70834 7.12502 8.70834ZM12.6667 9.50001C12.6667 9.06278 12.3122 8.70834 11.875 8.70834C11.4378 8.70834 11.0834 9.06278 11.0834 9.50001V14.25C11.0834 14.6872 11.4378 15.0417 11.875 15.0417C12.3122 15.0417 12.6667 14.6872 12.6667 14.25V9.50001Z"
			fill={color}
		/>
		<path
			d="M3.16667 4.75001C2.72944 4.75001 2.375 4.39557 2.375 3.95834C2.375 3.52112 2.72944 3.16668 3.16667 3.16668H5.67681C6.00285 2.24423 6.88258 1.58334 7.91667 1.58334H11.0833C12.1174 1.58334 12.9972 2.24423 13.3232 3.16668H15.8333C16.2706 3.16668 16.625 3.52112 16.625 3.95834C16.625 4.39557 16.2706 4.75001 15.8333 4.75001H3.16667Z"
			fill={color}
		/>
	</svg>
);
