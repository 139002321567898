import styled from 'styled-components';

export const Input = styled.input`
	border: 1px solid ${(props) => props.theme.colors.border};
	background: #fff;
	border-radius: 6px;
	box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.06);
	padding: 12px 16px;
	width: 100%;
	transition: all 0.2s linear;

	&:focus {
		outline-color: ${(props) => props.theme.colors.green};
	}
`;

export const InputWrapper = styled.div`
	margin-bottom: 24px;
`;
