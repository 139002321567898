import { Theme } from '../../../Theme';
import { IconProps } from '../../IconProps';

export const FileHouseIcon = ({
	size = 24,
	color = Theme.colors.black
}: IconProps): JSX.Element => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2.99311 9.10619C2.36096 9.67512 2 10.4856 2 11.3361V19C2 20.6568 3.34315 22 5 22H19C20.6569 22 22 20.6568 22 19V11.3361C22 10.4856 21.639 9.67512 21.0069 9.10619L14.0069 2.80619C12.866 1.77937 11.134 1.77937 9.9931 2.80619L2.99311 9.10619ZM11 13C9.89543 13 9 13.8954 9 15V19C9 19.5523 9.44772 20 10 20H14C14.5523 20 15 19.5523 15 19V15C15 13.8954 14.1046 13 13 13H11Z"
			fill={color}
		/>
	</svg>
);
