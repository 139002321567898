import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	ButtonProps,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	Spacer,
	Text,
	VStack,
	useDisclosure,
	useToast
} from '@chakra-ui/react';
import emailjs from '@emailjs/browser';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Theme } from '../../../Theme';
import { useInviteBidder } from '../../../mutations/procurement/useInviteBidder';
import { useGetUserByEmail } from '../../../queries/useGetUserByEmail';
import { devError } from '../../../utils/ConsoleUtils';

export const InviteButton = ({ tenderId, tenderDesc }): JSX.Element => {
	const [searchMail, setSearchMail] = useState('');
	const [inviteSuccess, setInviteSuccess] = useState(false);
	const inviteMutation = useInviteBidder();
	const searchMutation = useGetUserByEmail();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const toast = useToast();
	const search = useCallback(async () => {
		try {
			const response = await searchMutation.mutateAsync({
				email: searchMail
			});
			if (response.uId) {
				// devInfo('Found user with uId:', response.uId);
				// Add to tender
				inviteMutation.mutateAsync({ uId: response.uId, tenderId }).then((res) => {
					if (res.errorCode === 'OK') {
						setSearchMail('');
						setInviteSuccess(true); //! Fix this
						toast({
							title: 'User invited',
							description:
								'The user has been invited to make an offer to the tender, we also sent him an email.',
							status: 'success',
							duration: 3000,
							isClosable: true
						});
						sendEmailAccount();
						onClose();
					} else {
						throw new Error('Could not invite user.');
					}
				});
			} else {
				toast({
					title: 'User not found',
					description:
						'The user you tried to invite does not have an GigOver account. We will send an email asking him to create one. Note that you still have to invite him after he has created the account.',
					status: 'info',
					duration: 3000,
					isClosable: true
				});
				sendEmailNoAccount();
				onClose();
			}
		} catch (e) {
			//
			devError(e);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchMutation, searchMail]);

	useEffect(() => {
		if (inviteSuccess) {
			setTimeout(() => {
				setInviteSuccess(false);
			}, 3500);
		}
	}, [inviteSuccess]);
	// For the email we send if the user does not have a gigOver account.
	const emailServiceId = process.env.REACT_APP_EMAIL_SERVICE_ID;
	const emailTemplateIdNoAccount = process.env.REACT_APP_EMAIL_TEMPLATE_ID;
	const emailTemplateIdAccount = process.env.REACT_APP_EMAIL_TEMPLATE_ID_ACCOUNT;
	const emailUserId = 'yz_BqW8_gSHEh6eAL'; // this is a public key, so no reason to have it in .env

	// We send an email to ask the user to create a gigOver account if he doesn't have one.
	const sendEmailNoAccount = async () => {
		const templateParams = {
			tenderDesc,
			to_email: searchMail
		};
		console.log('Sending email to: ', searchMail);
		console.log('tenderDesc: ', templateParams.tenderDesc);
		try {
			await emailjs
				.send(emailServiceId!, emailTemplateIdNoAccount!, templateParams!, emailUserId!)
				.then(
					function (response) {
						console.log('SUCCESS!', response.status, response.text);
					},
					function (error) {
						console.log('FAILED...', error);
					}
				);

			onClose();
		} catch (e) {
			console.log(e);
		}
	};

	// We also want to send an email even though the user has an account.
	const sendEmailAccount = async () => {
		const templateParams = {
			tenderDesc,
			to_email: searchMail
		};
		console.log('Sending email to: ', searchMail);
		console.log('tenderDesc: ', templateParams.tenderDesc);
		try {
			await emailjs
				.send(emailServiceId!, emailTemplateIdAccount!, templateParams!, emailUserId!)
				.then(
					function (response) {
						console.log('SUCCESS!', response.status, response.text);
					},
					function (error) {
						console.log('FAILED...', error);
					}
				);

			onClose();
		} catch (e) {
			console.log(e);
		}
	};

	const handleOpenDialog: ButtonProps['onClick'] = (event) => {
		event.preventDefault();
		onOpen();
	};

	const cancelRef = useRef<HTMLButtonElement | null>(null);
	return (
		<>
			<Button ml={'1'} onClick={handleOpenDialog}>
				Invite Bidder
			</Button>

			<AlertDialog
				isOpen={isOpen}
				onClose={onClose}
				leastDestructiveRef={cancelRef}
				portalProps={{ appendToParentPortal: true }}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader>Invite user with email</AlertDialogHeader>

						<AlertDialogBody>
							<VStack spacing={4}>
								<Text>
									Invite a user to this tender. If the user does not have a
									GigOver account, he will receive an email asking him to create
									one. Note that you will need to invite him again after he has
									created the account.
								</Text>
								<FormControl
									isRequired={true}
									isInvalid={searchMutation.isError || inviteMutation.isError}
									mb={4}
								>
									<FormLabel htmlFor={'inviteEmail'}>E-mail</FormLabel>
									<Input
										placeholder={'Enter e-mail address of a Gigover user'}
										name={'inviteEmail'}
										value={searchMail}
										onChange={(e) => setSearchMail(e.target.value)}
									/>
									{inviteSuccess ? (
										<>
											<Text mt={4} color={Theme.colors.green}>
												User has been invited to the project
											</Text>
										</>
									) : (
										(searchMutation.isError || inviteMutation.isError) && (
											<>
												<FormErrorMessage>
													The user with email {searchMail} could not be
													found.
												</FormErrorMessage>
											</>
										)
									)}
								</FormControl>
							</VStack>
						</AlertDialogBody>
						<AlertDialogFooter>
							<Button
								variant={'outline'}
								colorScheme={'black'}
								ref={cancelRef}
								onClick={onClose}
							>
								Cancel
							</Button>
							<Spacer />
							<Button
								loadingText={'Inviting'}
								isLoading={searchMutation.isLoading || inviteMutation.isLoading}
								disabled={searchMutation.isLoading || inviteMutation.isLoading}
								onClick={search}
							>
								Invite
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	);
};
