import { IconProps } from '../IconProps';

export const UsersIcon = ({ scale = 1, color = 'white' }: IconProps): JSX.Element => {
	return (
		<svg
			width={scale * 24}
			height={scale * 25}
			viewBox="0 0 24 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M20.4844 12.9814H18.1351C17.8154 12.9814 17.5058 13.025 17.2112 13.1054C16.63 11.9629 15.4435 11.178 14.0763 11.178H9.92367C8.55647 11.178 7.36997 11.9629 6.78881 13.1054C6.4942 13.025 6.18464 12.9814 5.86491 12.9814H3.51562C1.57711 12.9814 0 14.5585 0 16.497V20.2525C0 21.4156 0.946266 22.3619 2.10938 22.3619H21.8906C23.0537 22.3619 24 21.4156 24 20.2525V16.497C24 14.5585 22.4229 12.9814 20.4844 12.9814ZM6.40805 14.6937V20.9557H2.10938C1.72167 20.9557 1.40625 20.6403 1.40625 20.2526V16.497C1.40625 15.3339 2.35252 14.3877 3.51562 14.3877H5.86491C6.05578 14.3877 6.24061 14.4136 6.41658 14.4613C6.41152 14.5383 6.40805 14.6156 6.40805 14.6937ZM16.1857 20.9557H7.8143V14.6937C7.8143 13.5305 8.76056 12.5843 9.92367 12.5843H14.0763C15.2394 12.5843 16.1857 13.5305 16.1857 14.6937V20.9557ZM22.5938 20.2526C22.5938 20.6403 22.2783 20.9557 21.8906 20.9557H17.592V14.6937C17.592 14.6155 17.5885 14.5382 17.5834 14.4613C17.7594 14.4136 17.9442 14.3876 18.1351 14.3876H20.4844C21.6475 14.3876 22.5938 15.3339 22.5938 16.497V20.2526Z"
				fill={color}
			/>
			<path
				d="M4.69032 6.34155C2.96771 6.34155 1.56628 7.74297 1.56628 9.46558C1.56624 11.1882 2.96771 12.5896 4.69032 12.5896C6.41288 12.5896 7.81435 11.1882 7.81435 9.46558C7.81435 7.74297 6.41293 6.34155 4.69032 6.34155ZM4.69027 11.1834C3.74307 11.1834 2.97249 10.4128 2.97249 9.46558C2.97249 8.51838 3.74307 7.7478 4.69027 7.7478C5.63747 7.7478 6.40805 8.51838 6.40805 9.46558C6.40805 10.4128 5.63747 11.1834 4.69027 11.1834Z"
				fill={color}
			/>
			<path
				d="M12 2.46143C9.69858 2.46143 7.82629 4.33371 7.82629 6.63508C7.82629 8.93645 9.69858 10.8087 12 10.8087C14.3013 10.8087 16.1736 8.93645 16.1736 6.63508C16.1736 4.33375 14.3013 2.46143 12 2.46143ZM12 9.40248C10.474 9.40248 9.23254 8.16105 9.23254 6.63508C9.23254 5.10916 10.474 3.86767 12 3.86767C13.5259 3.86767 14.7674 5.10911 14.7674 6.63508C14.7674 8.16105 13.5259 9.40248 12 9.40248Z"
				fill={color}
			/>
			<path
				d="M19.3098 6.34155C17.5872 6.34155 16.1857 7.74297 16.1857 9.46558C16.1858 11.1882 17.5872 12.5896 19.3098 12.5896C21.0324 12.5896 22.4338 11.1882 22.4338 9.46558C22.4338 7.74297 21.0324 6.34155 19.3098 6.34155ZM19.3098 11.1834C18.3626 11.1834 17.592 10.4128 17.592 9.46558C17.592 8.51838 18.3626 7.7478 19.3098 7.7478C20.257 7.7478 21.0275 8.51838 21.0275 9.46558C21.0275 10.4128 20.257 11.1834 19.3098 11.1834Z"
				fill={color}
			/>
		</svg>
	);
};
