import axios, { AxiosError } from 'axios';
import { ApiService } from '../services/ApiService';
import { useMutation, useQueryClient } from 'react-query';
import { devError } from '../utils/ConsoleUtils';
import { ProjectImage } from '../models/ProjectImage';

export interface DocumentInput
	extends Pick<
		ProjectImage,
		| 'propertyId'
		| 'offerId'
		| 'tenderId'
		| 'projectId'
		| 'type'
		| 'folderId'
		| 'name'
		| 'url'
		| 'bytes'
		| 'taskId'
	> {}

export const useAddDocument = () => {
	const client = useQueryClient();

	return useMutation<{ projectImage: ProjectImage }, AxiosError, DocumentInput>(
		async (variables) => {
			try {
				const response = await axios.post<{ projectImage: ProjectImage }>(
					ApiService.addImage,
					variables,
					{
						withCredentials: true
					}
				);
				await client.refetchQueries(ApiService.projectList);

				if (variables.folderId) {
					await client.refetchQueries(ApiService.folderFiles(variables.folderId));
				}

				return response.data;
			} catch (e) {
				devError(e);
				throw new Error('Could not upload image');
			}
		}
	);
};
