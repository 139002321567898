import { Theme } from '../../Theme';
import { IconProps } from '../IconProps';

export const ClockIcon = ({ color = Theme.colors.darkBlue, ...props }: IconProps): JSX.Element => (
	<svg
		width="16"
		height="20"
		viewBox="0 0 16 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M13.666 6.15336L14.8032 5.01624L13.6842 3.89692L12.4678 5.11337C11.3565 4.32585 10.0597 3.84049 8.70448 3.70483V2.08263H10.2871V0.5H5.53922V2.08263H7.12185V3.70483C5.7666 3.84049 4.46982 4.32585 3.35855 5.11337L2.1421 3.89692L1.02318 5.01624L2.1603 6.15336C1.09886 7.27719 0.389839 8.6873 0.120785 10.2095C-0.14827 11.7318 0.0344297 13.2995 0.646321 14.7191C1.25821 16.1387 2.2725 17.348 3.56391 18.1976C4.85533 19.0472 6.36732 19.5 7.91317 19.5C9.45901 19.5 10.971 19.0472 12.2624 18.1976C13.5538 17.348 14.5681 16.1387 15.18 14.7191C15.7919 13.2995 15.9746 11.7318 15.7055 10.2095C15.4365 8.6873 14.7275 7.27719 13.666 6.15336ZM7.91317 17.909C6.66111 17.909 5.43716 17.5377 4.39611 16.8421C3.35506 16.1465 2.54366 15.1578 2.06452 14.001C1.58538 12.8443 1.46001 11.5714 1.70428 10.3434C1.94854 9.1154 2.55147 7.98741 3.43681 7.10207C4.32215 6.21673 5.45014 5.6138 6.67814 5.36954C7.90614 5.12527 9.179 5.25064 10.3358 5.72978C11.4925 6.20892 12.4812 7.02032 13.1768 8.06137C13.8724 9.10243 14.2437 10.3264 14.2437 11.5784C14.2418 13.2568 13.5742 14.8659 12.3874 16.0527C11.2006 17.2395 9.59155 17.9071 7.91317 17.909Z"
			fill={color}
		/>
		<path
			d="M7.91391 6.83057V11.5785H3.16602C3.16602 12.5175 3.44447 13.4355 3.96618 14.2163C4.48789 14.997 5.22941 15.6056 6.09697 15.965C6.96454 16.3243 7.91918 16.4183 8.84018 16.2351C9.76118 16.0519 10.6072 15.5997 11.2712 14.9357C11.9352 14.2717 12.3874 13.4257 12.5706 12.5047C12.7538 11.5837 12.6598 10.6291 12.3004 9.76152C11.941 8.89396 11.3325 8.15244 10.5517 7.63073C9.77092 7.10903 8.85296 6.83057 7.91391 6.83057Z"
			fill={color}
		/>
	</svg>
);
