export const ImportantIcon = ({ size = 29 }): JSX.Element => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 29 29"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M28.25 14.5C28.25 22.0939 22.0939 28.25 14.5 28.25C6.90608 28.25 0.75 22.0939 0.75 14.5C0.75 6.90608 6.90608 0.75 14.5 0.75C22.0939 0.75 28.25 6.90608 28.25 14.5ZM14.5 13.125C15.2594 13.125 15.875 13.7406 15.875 14.5V21.3762C15.875 22.1356 15.2594 22.7512 14.5 22.7512C13.7406 22.7512 13.125 22.1356 13.125 21.3762V14.5C13.125 13.7406 13.7406 13.125 14.5 13.125ZM14.5 10.375C15.2594 10.375 15.875 9.75939 15.875 9C15.875 8.24061 15.2594 7.625 14.5 7.625C13.7406 7.625 13.125 8.24061 13.125 9C13.125 9.75939 13.7406 10.375 14.5 10.375Z"
				fill="black"
			/>
		</svg>
	);
};
