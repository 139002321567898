import { Theme } from '../../../Theme';
import { IconProps } from '../../IconProps';

export const FileImgIcon = ({ size = 24, color = Theme.colors.black }: IconProps): JSX.Element => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M9 2C7.34315 2 6 3.34315 6 5V6H5C3.34315 6 2 7.34315 2 9V19C2 20.6569 3.34315 22 5 22H15C16.6569 22 18 20.6569 18 19V18H19C20.6569 18 22 16.6569 22 15V5C22 3.34315 20.6569 2 19 2H9ZM16 18H9.00008C7.34323 18 6 16.6569 6 15V8H5C4.44772 8 4 8.44772 4 9V19C4 19.5523 4.44772 20 5 20H15C15.5523 20 16 19.5523 16 19V18ZM17.5 8C18.3284 8 19 7.32843 19 6.5C19 5.67157 18.3284 5 17.5 5C16.6716 5 16 5.67157 16 6.5C16 7.32843 16.6716 8 17.5 8ZM19.481 15.877C19.6931 15.7604 19.6787 15.4785 19.5154 15.2999C18.4834 14.1717 14.8537 10.204 12.9326 8.10824C11.9099 6.99253 10.1381 7.03436 9.16919 8.19709L8.23186 9.32188C8.0821 9.5016 8.00008 9.72813 8.00008 9.96207V15C8.00008 15.5523 8.4478 16 9.00008 16H19.0001C19.1744 16 19.3383 15.9554 19.481 15.877Z"
			fill={color}
		/>
	</svg>
);
