import { Theme } from '../../Theme';
import { IconProps } from '../IconProps';

export const FolderIcon = ({
	size = 24,
	color = Theme.colors.black,
	type = 'solid'
}: IconProps): JSX.Element => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			{(() => {
				switch (type) {
					case 'light':
					case 'medium':
					case 'bold':
						return (
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M11 7C10.4696 7 9.96086 6.78929 9.58579 6.41421L8.46447 5.29289C8.27693 5.10536 8.02258 5 7.75736 5H5C4.44772 5 4 5.44772 4 6V18C4 18.5523 4.44772 19 5 19H19C19.5523 19 20 18.5523 20 18V8C20 7.44772 19.5523 7 19 7H11ZM11 5H19C20.6569 5 22 6.34315 22 8V18C22 19.6569 20.6569 21 19 21H5C3.34315 21 2 19.6569 2 18V6C2 4.34315 3.34315 3 5 3H7.75736C8.55301 3 9.31607 3.31607 9.87868 3.87868L11 5Z"
								fill={color}
							/>
						);
					case 'solid':
					default: {
						return (
							<path
								d="M5 21H19C20.6569 21 22 19.6569 22 18V8C22 6.34315 20.6569 5 19 5H11.4142C11.149 5 10.8946 4.89464 10.7071 4.70711L9.87868 3.87868C9.31607 3.31607 8.55301 3 7.75736 3H5C3.34315 3 2 4.34315 2 6V18C2 19.6569 3.34315 21 5 21Z"
								fill={color}
							/>
						);
					}
				}
			})()}
		</svg>
	);
};
