interface GigoverLogoProps {
	scale?: number;
	color?: string;
}

export const GigoverLogo = ({ scale = 1, color = '#fff' }: GigoverLogoProps): JSX.Element => {
	return (
		<svg
			width={165 * scale}
			height={47 * scale}
			viewBox="0 0 165 47"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M20.9528 10.5405L20.6748 13.32C18.6864 10.9254 15.8429 9.68531 12.5503 9.68531C5.30233 9.68531 0 15.8642 0 23.3901C0 30.1036 4.5754 37.0522 12.4861 37.0522C15.8001 37.0522 18.5582 35.8121 20.5252 33.5458V34.0589C20.4824 37.5867 17.5533 40.0882 13.4483 40.0882C10.455 40.0882 8.08178 39.3399 6.97 38.1853L1.94561 42.3759C4.31883 45.0484 8.42386 46.6733 13.4483 46.6733C21.4017 46.6733 27.0675 41.4565 27.1103 34.0589V10.5405H20.9528ZM13.6193 30.467C9.32184 30.467 6.58515 26.8965 6.58515 23.3046C6.58515 19.5203 9.64255 16.2705 13.6193 16.2705C17.2326 16.2705 20.5252 18.943 20.5252 23.326C20.5252 27.5165 17.5319 30.467 13.6193 30.467Z"
				fill={color}
			/>
			<path
				d="M35.0972 7.44037C37.1497 7.44037 38.8173 5.7727 38.8173 3.72018C38.8173 1.66767 37.1497 0 35.0972 0C33.0446 0 31.377 1.66767 31.377 3.72018C31.377 5.7727 33.0446 7.44037 35.0972 7.44037ZM31.8046 36.197H38.3897V10.5405H31.8046V36.197Z"
				fill={color}
			/>
			<path
				d="M62.6257 10.5405L62.3477 13.32C60.3593 10.9254 57.5158 9.68531 54.2232 9.68531C46.9752 9.68531 41.6729 15.8642 41.6729 23.3901C41.6729 30.1036 46.2483 37.0522 54.159 37.0522C57.473 37.0522 60.2311 35.8121 62.1981 33.5458V34.0589C62.1553 37.5867 59.2262 40.0882 55.1212 40.0882C52.1279 40.0882 49.7547 39.3399 48.6429 38.1853L43.6185 42.3759C45.9917 45.0484 50.0968 46.6733 55.1212 46.6733C63.0747 46.6733 68.7404 41.4565 68.7832 34.0589V10.5405H62.6257ZM55.2922 30.467C50.9947 30.467 48.2581 26.8965 48.2581 23.3046C48.2581 19.5203 51.3154 16.2705 55.2922 16.2705C58.9055 16.2705 62.1981 18.943 62.1981 23.326C62.1981 27.5165 59.2048 30.467 55.2922 30.467Z"
				fill={color}
			/>
			<path
				d="M85.365 37.0522C92.9122 37.0522 99.027 30.9374 99.027 23.3687C99.027 15.8215 92.9122 9.68531 85.365 9.68531C77.7749 9.68531 71.6602 15.8215 71.6602 23.3687C71.6602 30.9374 77.7749 37.0522 85.365 37.0522ZM85.365 30.467C81.4096 30.467 78.2453 27.3027 78.2453 23.3687C78.2453 19.4561 81.4096 16.2705 85.365 16.2705C89.2776 16.2705 92.4419 19.4561 92.4419 23.3687C92.4419 27.3027 89.2776 30.467 85.365 30.467Z"
				fill={color}
			/>
			<path
				d="M115.652 10.5405L110.606 22.4708L105.56 10.5405H98.2265L110.499 37.0522H110.755L123.071 10.5405H115.652Z"
				fill={color}
			/>
			<path
				d="M147.584 22.3425C147.584 16.0994 143.116 9.68531 135.419 9.68531C128.128 9.68531 122.27 15.4366 122.27 23.3687C122.27 31.2367 128.043 37.0522 135.44 37.0522C138.476 37.0522 142.56 36.0687 145.767 32.6264L141.341 28.2221C139.096 30.2318 137.001 30.6381 135.59 30.6381C132.533 30.6381 130.074 28.7566 129.09 25.9771H146.964C147.349 24.9723 147.584 23.7536 147.584 22.3425ZM135.398 16.0994C138.455 16.0994 140.379 18.045 141.063 20.8459H128.983C129.881 17.9595 132.383 16.0994 135.398 16.0994Z"
				fill={color}
			/>
			<path
				d="M156.94 13.9828L156.598 10.5405H150.44V36.197H157.025V25.5282C157.025 17.7885 161.258 17.0052 164.145 17.0052C164.444 17.0052 164.743 17.0052 165 17.0265V10.5405C161.9 10.5619 159.441 10.7543 156.94 13.9828Z"
				fill={color}
			/>
		</svg>
	);
};
