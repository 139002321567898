// eslint-disable-next-line no-shadow
export enum FileUploadType {
	Project = 'Project',
	Task = 'Task',
	Comment = 'Comment',
	Folder = 'Folder',
	Tender = 'Tender',
	Offer = 'Offer',
	Property = 'Property'
}
