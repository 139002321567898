import { Page } from '../components/Page';

export const Users = (): JSX.Element => {
	return (
		<Page title={'Users'}>
			<div>
				<h1>Users</h1>
			</div>
		</Page>
	);
};
