export const LocationPinIcon = (): JSX.Element => {
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.0261 7.52609C15.0261 8.95118 14.5314 10.2607 13.7044 11.2924H13.7078C13.7078 11.2924 11.6135 14.095 9.99108 16.0795C9.47346 16.7126 8.52665 16.7123 8.00935 16.079C6.39209 14.0989 4.30045 11.2985 4.30045 11.2985L4.29557 11.2924C3.46856 10.2607 2.97388 8.95118 2.97388 7.52609C2.97388 4.19797 5.67185 1.5 8.99996 1.5C12.3281 1.5 15.0261 4.19797 15.0261 7.52609ZM11.2501 7.5C11.2501 8.74264 10.2427 9.75 9.00006 9.75C7.75742 9.75 6.75006 8.74264 6.75006 7.5C6.75006 6.25736 7.75742 5.25 9.00006 5.25C10.2427 5.25 11.2501 6.25736 11.2501 7.5Z"
				fill="#EA4335"
			/>
		</svg>
	);
};
