import { Theme } from '../../../Theme';
import { IconProps } from '../../IconProps';

export const FileVideoIcon = ({
	size = 24,
	color = Theme.colors.black
}: IconProps): JSX.Element => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M22 18C22 19.6569 20.6569 21 19 21H5C3.34315 21 2 19.6569 2 18V6C2 4.34315 3.34315 3 5 3H19C20.6569 3 22 4.34315 22 6V18ZM16 5.5C16 5.22386 15.7761 5 15.5 5H13.5C13.2239 5 13 5.22386 13 5.5V7C13 7.27614 13.2239 7.5 13.5 7.5H15.5C15.7761 7.5 16 7.27614 16 7V5.5ZM18 7C18 7.27614 18.2239 7.5 18.5 7.5H19.5C19.7761 7.5 20 7.27614 20 7V6C20 5.44771 19.5523 5 19 5H18.5C18.2239 5 18 5.22386 18 5.5V7ZM4 7C4 7.27614 4.22386 7.5 4.5 7.5H5.5C5.77614 7.5 6 7.27614 6 7V5.5C6 5.22386 5.77614 5 5.5 5H5C4.44772 5 4 5.44772 4 6V7ZM8 7C8 7.27614 8.22386 7.5 8.5 7.5H10.5C10.7761 7.5 11 7.27614 11 7V5.5C11 5.22386 10.7761 5 10.5 5H8.5C8.22386 5 8 5.22386 8 5.5V7ZM4.5 16.5C4.22386 16.5 4 16.7239 4 17V18C4 18.5523 4.44772 19 5 19H5.5C5.77614 19 6 18.7761 6 18.5V17C6 16.7239 5.77614 16.5 5.5 16.5H4.5ZM8.5 16.5C8.22386 16.5 8 16.7239 8 17V18.5C8 18.7761 8.22386 19 8.5 19H10.5C10.7761 19 11 18.7761 11 18.5V17C11 16.7239 10.7761 16.5 10.5 16.5H8.5ZM13.5 16.5C13.2239 16.5 13 16.7239 13 17V18.5C13 18.7761 13.2239 19 13.5 19H15.5C15.7761 19 16 18.7761 16 18.5V17C16 16.7239 15.7761 16.5 15.5 16.5H13.5ZM18.5 16.5C18.2239 16.5 18 16.7239 18 17V18.5C18 18.7761 18.2239 19 18.5 19H19C19.5523 19 20 18.5523 20 18V17C20 16.7239 19.7761 16.5 19.5 16.5H18.5Z"
			fill={color}
		/>
	</svg>
);
