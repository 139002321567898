import { Theme } from '../../Theme';

export const TenderIcon = ({ size = 24, color = Theme.colors.white }): JSX.Element => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 128 128">
			<path
				d="M70 1a3 3 0 00-3 3 3 3 0 003 3 3 3 0 003-3 3 3 0 00-3-3zM54.758 4.043c-.39 0-.783.083-1.158.258-10.3 4.3-18.8 12.7-23.2 23-.7 1.5 0 3.198 1.6 3.898.4.2.8.201 1.2.201 1.2 0 2.3-.7 2.8-1.8 3.8-8.9 11-16.1 19.9-19.8 1.5-.6 2.3-2.4 1.6-3.9a2.992 2.992 0 00-2.742-1.857zm-.82 13.996a2.72 2.72 0 00-1.637.56C50.1 20.1 48.2 21.9 46.5 23.9c-1.1 1.3-.9 3.2.3 4.2.6.5 1.3.7 2 .7.8 0 1.7-.4 2.3-1 1.4-1.6 3-3.1 4.7-4.3 1.4-1 1.7-2.8.7-4.2a3.224 3.224 0 00-2.563-1.26zM24 41c-4.4 0-8 3.6-8 8s3.6 8 8 8v27c0 1.7 1.3 3 3 3h23c1.1 0 2 .9 2 2s-.9 2-2 2H24c-1.1 0-2-.9-2-2 0-1.7-1.3-3-3-3s-3 1.3-3 3c0 4.4 3.6 8 8 8h26c4.4 0 8-3.6 8-8s-3.6-8-8-8v-9h70c1.7 0 3-1.3 3-3s-1.3-3-3-3H50v-9c4.4 0 8-3.6 8-8s-3.6-8-8-8H24zm0 6h26c1.1 0 2 .9 2 2s-.9 2-2 2H24c-1.1 0-2-.9-2-2s.9-2 2-2zm6 10h14v24H30V57zm-13 54c-1.7 0-3 1.3-3 3v7H9c-1.7 0-3 1.3-3 3s1.3 3 3 3h8c1.7 0 3-1.3 3-3v-7h14v4h-5c-1.7 0-3 1.3-3 3s1.3 3 3 3h38c1.7 0 3-1.3 3-3s-1.3-3-3-3h-7v-7c0-1.7-1.3-3-3-3H17zm65 10a3 3 0 00-3 3 3 3 0 003 3 3 3 0 003-3 3 3 0 00-3-3z"
				fill={color}
			/>
		</svg>
	);
};
